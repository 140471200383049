import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewOperation } from '@data-terminal/shared-models';
import { filter, Observable } from 'rxjs';
import { NewOperationDialogComponent } from '../../components/operations/operations-list/new-operation-dialog/new-operation-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class NewOperationDialogService {
    constructor(private readonly dialog: MatDialog) {}

    public openDialogAndFilter(data: NewOperation): Observable<NewOperation> {
        return this.dialog
            .open(NewOperationDialogComponent, {
                width: '320px',
                minHeight: '140px',
                maxHeight: '750px',
                height: '100%',
                disableClose: true,
                data,
            })
            .afterClosed()
            .pipe(filter((d) => d));
    }
}
