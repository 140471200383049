import { Component, Inject, NgModule } from '@angular/core';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SettingsConfirmationService } from '@data-terminal/feature-settings';
import { CommonModule } from '@angular/common';
@Component({
    templateUrl: './activity-confirmation-dialog.component.html',
})
export class ActivityConfirmationDialogComponent {
    public option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    public isChecked = false;

    constructor(
        private readonly dialogRef: MatDialogRef<ActivityConfirmationDialogComponent>,
        private readonly settingsConfirmationService: SettingsConfirmationService,
        @Inject(MAT_DIALOG_DATA) public dialogData: { activityName: string; isCleanup: boolean; isStopped: boolean }
    ) {}

    public closeDialog(): void {
        this.dialogRef.close(false);
    }

    public confirmActivity(): void {
        if (this.isChecked) {
            this.settingsConfirmationService.updateConfirmationEntry(this.dialogData.activityName, this.isChecked);
        }
        this.dialogRef.close(true);
    }
}

@NgModule({
    declarations: [ActivityConfirmationDialogComponent],
    exports: [ActivityConfirmationDialogComponent],
    imports: [HdmuiComponentsModule, MatCheckboxModule, TranslateModule, FormsModule, CommonModule],
})
export class ActivityConfirmationDialogModule {}
