import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, map, Observable, of, switchMap, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { NewOperation } from '@data-terminal/shared-models';

import { JobService } from '../../../../services/job/job.service';
import { requireMinValueOptional } from '@data-terminal/utils';
import { NgxMaskDirective } from 'ngx-mask';

@UntilDestroy()
@Component({
    templateUrl: './new-operation-dialog.component.html',
    styleUrls: ['./new-operation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewOperationDialogComponent implements OnInit {
    public readonly option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    public newOperationForm!: UntypedFormGroup;
    public jobIdSuggestions$!: Observable<string[]>;

    constructor(
        private readonly dialogRef: MatDialogRef<NewOperationDialogComponent>,
        private readonly jobService: JobService,
        @Inject(MAT_DIALOG_DATA) public data: NewOperation
    ) {
        this.createFormGroup();
    }

    ngOnInit(): void {
        this.listenForJobIdSuggestions();
    }

    public onCancelDialog(): void {
        this.dialogRef.close(false);
    }

    public onConfirmDialog(): void {
        const obj: NewOperation = { ...this.newOperationForm.value };
        this.dialogRef.close(obj);
    }

    private createFormGroup(): void {
        const formGroupGeneral = {
            opName: new UntypedFormControl(this.data?.opName ?? ''),
            jobId: new UntypedFormControl(this.data?.jobId ?? ''),
            comment: new UntypedFormControl(this.data?.comment ?? ''),
            makeReadyTimeHour: new UntypedFormControl(this.data?.makeReadyTimeHour ?? ''),
            makeReadyTimeMinutes: new UntypedFormControl(this.data?.makeReadyTimeMinutes ?? ''),
            productionRunTimeHours: new UntypedFormControl(this.data?.productionRunTimeHours ?? ''),
            productionRunTimeMinutes: new UntypedFormControl(this.data?.productionRunTimeMinutes ?? ''),
        };
        const formGroupAmounts = {
            goodAmount: new UntypedFormControl(this.data?.goodAmount ?? ''),
            wasteAmount: new UntypedFormControl(this.data?.wasteAmount ?? ''),
        };
        const formValidatorsGeneral = [
            requireMinValueOptional('makeReadyTimeHour', 0),
            requireMinValueOptional('makeReadyTimeMinutes', 0),
            requireMinValueOptional('productionRunTimeHours', 0),
            requireMinValueOptional('productionRunTimeMinutes', 0),
        ];
        const formValidatorsAmounts = [
            requireMinValueOptional('goodAmount', 0),
            requireMinValueOptional('wasteAmount', 0),
        ];
        if (this.data?.noAmountsRequired) {
            this.newOperationForm = new UntypedFormGroup(formGroupGeneral, formValidatorsGeneral);
        } else {
            this.newOperationForm = new UntypedFormGroup({ ...formGroupGeneral, ...formGroupAmounts }, [
                ...formValidatorsGeneral,
                ...formValidatorsAmounts,
            ]);
        }
    }

    private listenForJobIdSuggestions(): void {
        const JOB_SUGGESTION_MIN_LENGTH = 3;
        const jobIdFC: FormControl = this.newOperationForm.get('jobId') as FormControl;

        if (jobIdFC) {
            this.jobIdSuggestions$ = jobIdFC.valueChanges.pipe(
                untilDestroyed(this),
                debounceTime(1000),
                distinctUntilChanged(),
                switchMap((jobId: string) =>
                    jobId.length >= JOB_SUGGESTION_MIN_LENGTH ? this.jobService.getJobList(jobId).pipe(take(1)) : of([])
                ),
                map((jobList) => jobList.map((job) => job.jobId))
            );
        }
    }
}

@NgModule({
    declarations: [NewOperationDialogComponent],
    exports: [NewOperationDialogComponent],
    imports: [
        HdmuiComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
        MatDividerModule,
        MatIconModule,
        MatAutocompleteModule,
        NgxMaskDirective,
    ],
})
export class NewOperationDialogModule {}
