<mat-card appearance="outlined" class="sign-off-employee-card hdmui-m-5">
    <div class="sign-off-employee-card__headers hdmui-m-5">
        <h1 class="hdmui-font-serif hdmui-mb-0">
            {{ machine.machineName }}
        </h1>
        <h1 class="hdmui-font-bold hdmui-my-0">
            {{ machine.machineId }}
        </h1>
    </div>
    <div class="sign-off-employee-card__buttons">
        <form [formGroup]="formGroup">
            <mat-radio-group
                formControlName="assistantsSignOff"
                [name]="'deputy' + machine.machineId"
                (change)="setSelectState($event)"
            >
                <mat-radio-button class="hdmui-my-4 hdmui-me-4" [name]="'deputy' + machine.machineId" [value]="LEAVE"
                    >{{ 'DC.SIGN_OFF_DIALOG.EMPLOYEES.CARD.LEAVE' | translate }}
                </mat-radio-button>
                <mat-radio-button
                    class="hdmui-my-4 hdmui-me-4"
                    [name]="'deputy' + machine.machineId"
                    [value]="STAY"
                    [disabled]="(machine.signedOnUsers | filterAvailableCaptains).length === 0"
                >
                    {{ 'DC.SIGN_OFF_DIALOG.EMPLOYEES.CARD.STAY' | translate }}
                    <mat-form-field>
                        <mat-label>Deputy</mat-label>
                        <mat-select formControlName="newCaptain" [hideSingleSelectionIndicator]="true">
                            @for (option of machine.signedOnUsers | filterAvailableCaptains; track option) {
                                @if (option.userId !== operatorId) {
                                    <mat-option [value]="option.userId">{{ option.userId }} </mat-option>
                                }
                            }
                        </mat-select>
                    </mat-form-field>
                </mat-radio-button>
            </mat-radio-group>
        </form>
    </div>
</mat-card>
