import { Component, Inject, NgModule } from '@angular/core';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsConfirmationService } from '@data-terminal/feature-settings';

@Component({
    selector: 'data-terminal-cleanup-hint-dialog',
    templateUrl: './cleanup-hint-dialog.component.html',
    styleUrls: ['./cleanup-hint-dialog.component.sass'],
})
export class CleanupHintDialogComponent {
    public option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    constructor(
        private readonly dialogRef: MatDialogRef<CleanupHintDialogComponent>,
        private readonly settingsConfirmationService: SettingsConfirmationService,
        @Inject(MAT_DIALOG_DATA) public dialogData: { activityName: string; isCleanup: boolean; isStopped: boolean }
    ) {}

    public closeDialog(): void {
        this.dialogRef.close(false);
    }

    public confirmActivity(): void {
        this.dialogRef.close(true);
    }
}

@NgModule({
    declarations: [CleanupHintDialogComponent],
    exports: [CleanupHintDialogComponent],
    imports: [HdmuiComponentsModule, MatCheckboxModule, TranslateModule, FormsModule, CommonModule],
})
export class CleanupHintDialogModule {}
