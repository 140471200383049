import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { filter } from 'rxjs';
import { ActivityState, OperationAction, OperationActionType } from '@data-terminal/shared-models';
import { MatDialog } from '@angular/material/dialog';
import {
    ReportLastAmountDialogData,
    ReportLastAmountsDialogComponent,
} from './report-last-amounts-dialog/report-last-amounts-dialog.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'data-terminal-operation-toolbar-buttons',
    templateUrl: './operation-toolbar-buttons.component.html',
    styleUrls: ['./operation-toolbar-buttons.component.scss'],
})
export class OperationToolbarButtonsComponent implements OnChanges, OnInit {
    @Output() public sendReport: EventEmitter<OperationAction> = new EventEmitter<OperationAction>();
    @Input() public opState!: string;
    @Input() public plannedGoodAmount!: number;
    @Input() public plannedWasteAmount!: number;
    @Input() public currentGoodAmount!: number;
    @Input() public currentWasteAmount!: number;
    @Input() public stopButtonOnly = false;
    @Input() public isCounterBox = false;
    @Input() public counterBoxTimestamp = '';
    @Input() public captainFlag = false;
    @Input() public isManualMachine = false;

    public arePauseAndReportButtonsDisabled = true;
    public isStopButtonDisabled = true;
    public isStopHighlighted = false;
    public isPauseHighlighted = false;

    constructor(private readonly dialog: MatDialog) {}

    public ngOnInit(): void {
        this.updateButtonsConfig();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.opState) {
            this.updateButtonsConfig();
        }
    }

    public onPauseButtonClick(): void {
        if (this.arePauseAndReportButtonsDisabled) {
            return;
        }
        if (!this.isManualMachine) {
            this.openDialog(OperationActionType.PAUSE, 'DC.REPORT_LAST_AMOUNTS.PAUSE_HEADER');
        } else {
            this.openDialog(OperationActionType.PAUSE, 'DC.REPORT_LAST_AMOUNTS.PAUSE_HEADER_MANUAL_DEVICE');
        }
    }

    public onStopButtonClick(): void {
        if (this.isStopButtonDisabled) {
            return;
        }

        if (this.stopButtonOnly) {
            this.sendReport.emit({
                actionType: OperationActionType.STOP,
            } as OperationAction);
        } else {
            if (!this.isManualMachine) {
                this.openDialog(OperationActionType.STOP, 'DC.REPORT_LAST_AMOUNTS.FINISH_HEADER');
            } else {
                this.openDialog(OperationActionType.STOP, 'DC.REPORT_LAST_AMOUNTS.FINISH_HEADER_MANUAL_DEVICE');
            }
        }
    }

    public onReportButtonClick(): void {
        if (this.arePauseAndReportButtonsDisabled) {
            return;
        }

        this.openDialog(OperationActionType.ADD_REPORT, 'DC.REPORT_LAST_AMOUNTS.ADD_REPORT_HEADER');
    }

    public openDialog(dialogType: OperationActionType, i18nHeaderKey: string): void {
        const data: ReportLastAmountDialogData = {
            dialogType,
            i18nHeaderKey,
            plannedGoodAmount: this.plannedGoodAmount,
            plannedWasteAmount: this.plannedWasteAmount,
            currentGoodAmount: this.currentGoodAmount,
            currentWasteAmount: this.currentWasteAmount,
            counterBoxTimestamp: this.counterBoxTimestamp,
            isManualMachine: this.isManualMachine,
        };

        this.dialog
            .open(ReportLastAmountsDialogComponent, {
                width: '320px',
                minHeight: '140px',
                maxHeight: '640px',
                height: '100%',
                data,
                disableClose: true,
                autoFocus: false,
            })
            .afterClosed()
            .pipe(filter((d) => d))
            .subscribe({
                next: (report: OperationAction) => {
                    this.sendReport.emit(report);
                },
            });
    }

    private updateButtonsConfig(): void {
        this.arePauseAndReportButtonsDisabled = true;
        this.isStopButtonDisabled = true;
        this.isPauseHighlighted = false;
        this.isStopHighlighted = false;

        if (this.opState === ActivityState.SETUP || this.opState === ActivityState.IN_PROGRESS) {
            this.arePauseAndReportButtonsDisabled = false;
            this.isStopButtonDisabled = false;
            return;
        }

        if (this.opState === ActivityState.COMPLETED) {
            this.isStopHighlighted = true;
            return;
        }

        if (this.opState === ActivityState.PAUSED || this.opState === ActivityState.SUSPENDED) {
            this.isPauseHighlighted = true;
            if (this.opState === ActivityState.SUSPENDED) {
                this.isStopButtonDisabled = false;
            }
        }
    }
}

@NgModule({
    declarations: [OperationToolbarButtonsComponent],
    exports: [OperationToolbarButtonsComponent],
    imports: [MatButtonModule, MatIconModule, CommonModule],
})
export class OperationToolbarButtonsModule {}
