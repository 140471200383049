<div class="toolbar-button-panel">
    @if (!stopButtonOnly) {
        <button
            mat-icon-button
            [disabled]="arePauseAndReportButtonsDisabled || !captainFlag"
            [class.toolbar-button-panel__active-action-button]="isPauseHighlighted"
            (click)="onPauseButtonClick()"
        >
            <mat-icon svgIcon="hdmui:videoControlPause"></mat-icon>
        </button>
    }
    <button
        mat-icon-button
        [disabled]="isStopButtonDisabled || !captainFlag"
        [class.toolbar-button-panel__active-action-button]="isStopHighlighted"
        (click)="onStopButtonClick()"
    >
        <mat-icon svgIcon="hdmui:videoControlStop"></mat-icon>
    </button>
    @if (!stopButtonOnly) {
        <button
            mat-icon-button
            [disabled]="arePauseAndReportButtonsDisabled || !captainFlag || isCounterBox || isManualMachine"
            (click)="onReportButtonClick()"
        >
            <mat-icon svgIcon="hdmui:totalisatorStand"></mat-icon>
        </button>
    }
</div>
