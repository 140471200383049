import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, Observable, of, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import {
    Activity,
    ActivityRequired,
    GroupedActivities,
    Operation,
    OperationAction,
    OperationActionType,
} from '@data-terminal/shared-models';
import { SettingsConfirmationService } from '@data-terminal/feature-settings';

import { ActivityConfirmationDialogComponent } from '../activity-confirmation-dialog/activity-confirmation-dialog.component';
import { CleanupHintDialogComponent } from '../cleanup-hint-dialog/cleanup-hint-dialog.component';
import {
    ReportLastAmountDialogData,
    ReportLastAmountsDialogComponent,
} from '../../../operations/operation-toolbar-buttons/report-last-amounts-dialog/report-last-amounts-dialog.component';

@Component({
    selector: 'data-terminal-activity-buttons',
    templateUrl: './activity-buttons.component.html',
    styleUrls: ['./activity-buttons.component.scss'],
    standalone: true,
    imports: [NgClass, MatTooltip, MatButton, MatExpansionModule, NgTemplateOutlet],
})
export class ActivityButtonsComponent {
    @Input() public currentState!: string;
    @Input() public currentOpState!: string;
    @Input() public groupedActivities: GroupedActivities[] = [];
    @Input() public captainFlag = false;
    @Input() public operation?: Operation;
    @Input() public isManualMachine = false;
    @Output() public activityClick: EventEmitter<Activity> = new EventEmitter<Activity>();
    @Output() public sendReport: EventEmitter<OperationAction> = new EventEmitter<OperationAction>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly settingsConfirmationService: SettingsConfirmationService,
        private readonly activatedRoute: ActivatedRoute
    ) {}

    public onActivityClick(activity: Activity): void {
        if (this.canOpenCleanupHintDialog(activity)) {
            this.openCleanupHintDialog(
                activity.actName,
                activity.jobStatus === 'Cleanup',
                activity.jobStatus === 'Stopped'
            )
                .pipe(switchMap((cleanupResult) => (cleanupResult ? this.openConfirmationDialog(activity) : of(false))))
                .subscribe((confirmed) => {
                    if (confirmed && this.operation !== undefined && this.operation !== null) {
                        const data: ReportLastAmountDialogData = {
                            dialogType: OperationActionType.ADD_REPORT,
                            i18nHeaderKey: 'DC.REPORT_LAST_AMOUNTS.ADD_REPORT_HEADER',
                            isManualMachine: this.isManualMachine,
                            plannedGoodAmount: this.operation.opPlannedGoodCtr,
                            plannedWasteAmount: this.operation.opPlannedWasteCtr,
                            currentGoodAmount: this.operation.goodAmount,
                            currentWasteAmount: this.operation.wasteAmount,
                            counterBoxTimestamp: '',
                        };
                        this.dialog
                            .open(
                                ReportLastAmountsDialogComponent,
                                ReportLastAmountsDialogComponent.returnDialogConfigData(data)
                            )
                            .afterClosed()
                            .pipe(filter((d) => d))
                            .subscribe((operationAction: OperationAction) => {
                                this.sendReport.emit(operationAction);
                                this.activityClick.emit(activity);
                            });
                    }
                });
        } else {
            this.openConfirmationDialog(activity).subscribe((confirmed) => {
                if (confirmed) {
                    this.activityClick.emit(activity);
                }
            });
        }
    }

    private canOpenCleanupHintDialog(activity: Activity): boolean {
        return (
            (activity.jobStatus === 'Cleanup' || activity.jobStatus === 'Stopped') &&
            ActivityRequired.OTHER_ACTIVITY !== this.activatedRoute.snapshot.data.id &&
            this.currentOpState !== 'Stopped' &&
            this.currentOpState !== 'Cleanup'
        );
    }

    private openCleanupHintDialog(activityName: string, isCleanup: boolean, isStopped: boolean): Observable<boolean> {
        const dialogData = {
            width: '320px',
            minHeight: '140px',
            data: { activityName, isCleanup, isStopped },
            disableClose: true,
        };

        return this.dialog
            .open(CleanupHintDialogComponent, dialogData)
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    return of(!!result);
                })
            );
    }

    private openConfirmationDialog(activity: Activity): Observable<boolean> {
        const dialogData = {
            width: '320px',
            minHeight: '140px',
            data: { activityName: activity.actName, isCleanup: false, isStopped: false },
            disableClose: true,
        };
        if (this.settingsConfirmationService.showConfirmationDialog(activity.actName)) {
            return this.dialog
                .open(ActivityConfirmationDialogComponent, dialogData)
                .afterClosed()
                .pipe(
                    switchMap((result) => {
                        return of(!!result);
                    })
                );
        } else {
            return of(true);
        }
    }
}
