<form [formGroup]="reportAmountsForm" class="report">
    <hdmui-base-dialog
        [okEnabled]="isInputValid()"
        [dialogOptions]="option"
        (cancelClicked)="onCancelDialog()"
        (okClicked)="onConfirmDialog()"
        [contentNoPadding]="true"
        [okText]="'DC.REPORT_LAST_AMOUNTS.DIALOG.OKTEXT' | translate"
        [cancelText]="'DC.REPORT_LAST_AMOUNTS.DIALOG.CANCELTEXT' | translate"
    >
        <div class="report-form">
            <h2 class="hdmui-m-3">{{ dialogData.i18nHeaderKey | translate }}</h2>
            @if (dialogData.counterBoxTimestamp !== undefined && dialogData.counterBoxTimestamp !== '') {
                <p class="hdmui-caption">
                    {{ 'DC.REPORT_LAST_AMOUNTS.COUNTERBOX_MSG' | translate }}: {{ dialogData.counterBoxTimestamp }}
                </p>
            }
            <div class="hdmui-m-3 report-form__container">
                @if (!dialogData.isManualMachine) {
                    <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__info">
                        {{ 'DC.REPORT_LAST_AMOUNTS.REQUIRE_INFO' | translate }}
                    </p>
                }
                @if (!dialogData.isManualMachine) {
                    <mat-form-field class="hdmui-mt-0 hdmui-pt-0 report-form__input">
                        <mat-label>
                            {{ 'DC.REPORT_LAST_AMOUNTS.GOOD' | translate }}
                        </mat-label>
                        <input
                            matInput
                            formControlName="goodAmount"
                            (blur)="onInputOut()"
                            #goodAmountInput
                            mask="separator.0"
                        />
                    </mat-form-field>
                }

                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__value-hint">
                    @if (dialogData.plannedGoodAmount - dialogData.currentGoodAmount > 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.TO_GO'
                                | translate
                                    : { value: dialogData.plannedGoodAmount - dialogData.currentGoodAmount | number }
                        }}
                    }
                    @if (dialogData.plannedGoodAmount - dialogData.currentGoodAmount < 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.ABOVE'
                                | translate
                                    : { value: -(dialogData.plannedGoodAmount - dialogData.currentGoodAmount) | number }
                        }}
                    }
                </p>
                @if (!dialogData.isManualMachine) {
                    <mat-form-field class="hdmui-m-0 hdmui-p-0 report-form__input">
                        <mat-label>
                            {{ 'DC.REPORT_LAST_AMOUNTS.WASTE' | translate }}
                        </mat-label>
                        <input matInput formControlName="wasteAmount" (blur)="onInputOut()" mask="separator.0" />
                    </mat-form-field>
                }
                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__value-hint">
                    @if (dialogData.plannedWasteAmount - dialogData.currentWasteAmount > 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.TO_GO'
                                | translate
                                    : { value: dialogData.plannedWasteAmount - dialogData.currentWasteAmount | number }
                        }}
                    }
                    @if (dialogData.plannedWasteAmount - dialogData.currentWasteAmount < 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.ABOVE'
                                | translate
                                    : {
                                          value:
                                              -(dialogData.plannedWasteAmount - dialogData.currentWasteAmount) | number,
                                      }
                        }}
                    }
                </p>

                <mat-form-field class="hdmui-mb-4">
                    <mat-label>{{ 'DC.REPORT_LAST_AMOUNTS.DIALOG.COMMENT' | translate }}</mat-label>
                    <textarea matInput rows="3" formControlName="comment"></textarea>
                </mat-form-field>
                <data-terminal-amount-report-details
                    [formData]="formData$ | async"
                    [operationData]="operationData$ | async"
                    [isFinishOperation]="dialogData.dialogType === OperationActionType.STOP"
                    [isManualMachine]="!!dialogData.isManualMachine"
                ></data-terminal-amount-report-details>
            </div>
        </div>
    </hdmui-base-dialog>
</form>
