import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
    HdmuiAvatarComponent,
    HdmuiComponentsModule,
    HdmuiEmptyStatesModule,
    HdmuiIconsModule,
    HdmuiInfoBoxModule,
} from '@heidelberg/hdmui-angular';
import {
    Activity,
    ActivityState,
    ErrorCode,
    GroupedActivities,
    Operation,
    OperationAction,
    RequestType,
    ROUTE_PARAMS,
    SnackbarClass,
    UserMachines,
} from '@data-terminal/shared-models';
import { CommonModule, DOCUMENT } from '@angular/common';
import { EchartTimeIndicatorModule } from '../../shared-components/echart-time-indicator/echart-time-indicator.component';
import { OperationAmountInfoModule } from '../operation-amount-info/operation-amount-info.component';
import { OperationToolbarButtonsModule } from '../operation-toolbar-buttons/operation-toolbar-buttons.component';
import { ActivityButtonsComponent } from '../../shared-components/activity-buttons/default-activity-buttons/activity-buttons.component';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OperationPresenter } from '../../../pages/operations-page/operation.presenter';
import { groupProductionActivities } from '../../../services/operation/operation.functions';
import { filter, interval, Observable, Subject, take, tap } from 'rxjs';
import { RequestMetadata, SnackBarService } from '@data-terminal/utils';
import {
    ErrorMessageComponentModule,
    HelpButtonComponent,
    LoadingIndicatorComponentModule,
    MetadataDirectivesModule,
} from '@data-terminal/ui-presentational';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OperationService } from '../../../services/operation/operation.service';
import { SettingsConfirmationService, SettingsDataTransferService } from '@data-terminal/feature-settings';
import { MatDialog } from '@angular/material/dialog';
import { ActivityConfirmationDialogComponent } from '../../shared-components/activity-buttons/activity-confirmation-dialog/activity-confirmation-dialog.component';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    DataTerminalSessionService,
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
} from '@data-terminal/data-access';
import { ErrorDialogComponent } from '../../../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';
import { IOTResponseError } from '../../../../../../../projects/shared-models/src/lib/datatransfer';
import { ShowOrderbagDialogComponent } from '../../../dialogs/show-orderbag-dialog/show-orderbag-dialog.component';
import { LegalLineComponent } from '../../../../../../../src/app/components/legal-line/legal-line.component';
import { MachineToolbarService } from '../../../services/machine-toolbar/machine-toolbar.service';

const COUNTERBOX_REFRESH_INTERVAL = 20000;

@UntilDestroy()
@Component({
    selector: 'data-terminal-operation',
    templateUrl: './operation.component.html',
    styleUrls: ['./operation.component.scss'],
    providers: [MachineToolbarService],
})
export class OperationComponent implements OnInit {
    public groupedActivities: GroupedActivities[] = [];
    public operation$!: Observable<RequestMetadata<Operation>>;
    private readonly machineId: string;
    private primaryKey = '';
    public isOperationRunning = false;
    public currentTime = 0;
    public plannedTime = 0;
    public inProgress = ActivityState.IN_PROGRESS;
    public setUp = ActivityState.SETUP;
    public stopped = ActivityState.STOPPED;
    public cleanUp = ActivityState.CLEANUP;
    public transfer2Cloud: boolean;
    public machineRunningOperationPrimaryKey = '';
    public runningOnOtherMachine = false;
    public gotUpdateSinceActivityClick = false;
    public operationIsRunning = false;
    public actualMachine = '';
    public captainFlag = false;
    public userMachineSnapshot = {} as UserMachines;

    public isCounterBox = false;
    public counterBoxTimestamp = '';
    public isManualMachine = false;

    private detailsPageInformation = {
        opState: '',
    };

    private readonly userMachines$: Observable<RequestMetadata<UserMachines>> = this.globalState.select(
        GlobalStateSelectors.USER_MACHINES
    );
    private readonly notifier$: Subject<boolean> = new Subject<boolean>();

    readonly machineToolbarData$ = this.machineToolbarService.machineToolbarData$;

    constructor(
        private readonly operationPresenter: OperationPresenter,
        private readonly activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        private readonly translate: TranslateService,
        private readonly snackBarService: SnackBarService,
        private readonly operationService: OperationService,
        private readonly dialog: MatDialog,
        private readonly settingsConfirmationService: SettingsConfirmationService,
        private readonly settingsDataTransferService: SettingsDataTransferService,
        private readonly dtService: DataTerminalSessionService,
        private readonly router: Router,
        private readonly machineToolbarService: MachineToolbarService
    ) {
        const { userMachines } = this.globalState.get();
        this.userMachineSnapshot = userMachines.data!;

        this.groupedActivities = groupProductionActivities(this.activatedRoute.snapshot.data.activities);
        this.groupedActivities[0].showExpandedPanel = false;
        this.machineId = this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId] || '';
        this.userMachines$.pipe(take(1)).subscribe((data) => {
            if (data.data !== null && data.data !== undefined) {
                const currentMachineData = data.data.assignedMachines.find((ele) => ele.machineId === this.machineId);
                this.primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey] || '';
                this.machineRunningOperationPrimaryKey =
                    data.data.allMachines.find((machine) => machine.machineId === this.machineId)?.runningOperation
                        ?.primaryKey || '';
                this.runningOnOtherMachine = this.machineRunningOperationPrimaryKey !== this.primaryKey;
                this.isManualMachine = currentMachineData?.machineClass === 'ID_ManualPrePress';
                if (currentMachineData?.isCounterBox !== null && currentMachineData?.isCounterBox !== undefined) {
                    this.isCounterBox = currentMachineData.isCounterBox;
                    if (this.isCounterBox) {
                        let date = new Date();
                        let timestampHours = date.getHours().toString().padStart(2, '0');
                        let timestampMinutes = date.getMinutes().toString().padStart(2, '0');
                        let timestampSeconds = date.getSeconds().toString().padStart(2, '0');
                        this.counterBoxTimestamp = `${timestampHours}:${timestampMinutes}:${timestampSeconds}`;
                        interval(COUNTERBOX_REFRESH_INTERVAL)
                            .pipe(untilDestroyed(this))
                            .subscribe(() => {
                                const primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey];
                                if (primaryKey !== '') {
                                    this.operationPresenter.triggerCounterboxUpdate(primaryKey);
                                    date = new Date();
                                    timestampHours = date.getHours().toString().padStart(2, '0');
                                    timestampMinutes = date.getMinutes().toString().padStart(2, '0');
                                    timestampSeconds = date.getSeconds().toString().padStart(2, '0');
                                    this.counterBoxTimestamp = `${timestampHours}:${timestampMinutes}:${timestampSeconds}`;
                                }
                            });
                    }
                }
            }

            this.globalState
                .select(GlobalStateSelectors.USER_MACHINES)
                .pipe(untilDestroyed(this))
                .subscribe((userMachinesData) => {
                    if (userMachinesData.data !== null && userMachinesData.data !== undefined) {
                        this.machineRunningOperationPrimaryKey =
                            userMachinesData.data.allMachines.find((machine) => machine.machineId === this.machineId)
                                ?.runningOperation?.primaryKey || '';
                        this.actualMachine =
                            userMachinesData.data.allMachines.find(
                                (machine) => machine.runningOperation?.primaryKey === this.primaryKey
                            )?.machineId || '';
                        this.primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey] || '';
                        this.runningOnOtherMachine = this.machineRunningOperationPrimaryKey !== this.primaryKey;
                        this.gotUpdateSinceActivityClick = true;
                    }
                });

            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
                this.primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey] || '';
                this.runningOnOtherMachine = this.machineRunningOperationPrimaryKey !== this.primaryKey;
            });
        });

        this.operation$ = this.operationPresenter.selectedOperation$.pipe(
            tap(() => {
                this.runningOnOtherMachine = false;
                const primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey] || '';
                this.primaryKey = primaryKey;
                this.machineRunningOperationPrimaryKey =
                    this.userMachineSnapshot.allMachines.find((data) => data.machineId === this.machineId)
                        ?.runningOperation?.primaryKey || '';
                this.runningOnOtherMachine = this.machineRunningOperationPrimaryKey !== this.primaryKey;
            }),
            tap((metadata) => this.setTimeForEchart(metadata)),
            tap(
                (metadata) =>
                    (this.primaryKey =
                        metadata.data?.primaryKey || this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey] || '')
            ),
            tap((metadata) => (this.operationIsRunning = metadata.data?.running || false)),
            tap(() => {
                this.notifier$.next(true);
            }),
            tap((metadata) => (this.detailsPageInformation = metadata.data || ({} as Operation)))
        );

        this.transfer2Cloud = this.settingsDataTransferService.getDataTransferSettings()?.orderbag || false;

        this.globalState.select(GlobalStateSelectors.USER_MACHINES).subscribe((data) => {
            const userMachinesData = data.data;
            if (userMachinesData !== null && userMachinesData !== undefined) {
                this.captainFlag =
                    userMachinesData.allMachines
                        .find((machine) => machine.machineId === this.machineId)
                        ?.signedOnUsers.find(
                            (user) => user.userId === this.dtService.getCurrentDataTerminalUser().userId
                        )?.captain || false;
            }
        });
    }

    public ngOnInit(): void {
        const primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey] || '';
        this.operationPresenter.triggerSelectOperation(primaryKey);
        const { userMachines } = this.globalState.get();
        this.userMachineSnapshot = userMachines.data!;
    }

    public onActivityClick(activity: Activity, operation: Operation): void {
        this.gotUpdateSinceActivityClick = false;
        this.operationPresenter.triggerStartOperationActivity(
            activity,
            operation,
            this.machineId,
            this.counterBoxTimestamp || ''
        );
    }

    public onOperationActionClick(operationAction: OperationAction, operation: Operation): void {
        this.operationPresenter.triggerSendOperationAction(operationAction, this.machineId, operation.primaryKey);
    }

    public onOrderBagClick(jobNumber: string): void {
        let timeout = true;
        this.operationPresenter.triggerOrderBag(jobNumber).subscribe({
            next: (data) => {
                if (data !== null && data !== undefined) {
                    timeout = false;
                    if (typeof data === 'string') {
                        if (data === 'NullPointerException: ' || data === 'ERROR') {
                            this.snackBarService.openSnackBar(
                                this.translate.instant('DC.OPERATION_PAGE.SNACKBAR.WARNING'),
                                SnackbarClass.WARNING
                            );
                        } else {
                            const dialogData = {
                                height: '80%',
                                data: {
                                    base64String: data,
                                    orderbagNumber: jobNumber,
                                },
                            };
                            this.dialog.open(ShowOrderbagDialogComponent, dialogData);
                        }
                    } else {
                        const errorData = data as IOTResponseError;
                        this.dialog.open(ErrorDialogComponent, {
                            disableClose: true,
                            data: {
                                errorCode: errorData.errorcode,
                                errorMessage: JSON.stringify({
                                    payload: errorData.payload,
                                    request: errorData.request,
                                    deviceId: errorData.deviceId,
                                }),
                            },
                        });
                    }
                }
            },
            error: () => {
                this.snackBarService.openSnackBar(
                    this.translate.instant('DC.OPERATION_PAGE.SNACKBAR.ERROR'),
                    SnackbarClass.ERROR
                );
            },
            complete: () => {
                if (timeout) {
                    this.dialog.open(ErrorDialogComponent, {
                        disableClose: true,
                        data: {
                            errorCode: ErrorCode.ERR_GET_ORDERBAG,
                            errorMessage: JSON.stringify({
                                payload: {},
                                request: RequestType.ORDERBAG,
                                deviceId: '',
                            }),
                        },
                    });
                }
            },
        });
    }

    public onPrintPalletSheetClick(operation: Operation): void {
        const ACTIVITY = 'PrintPalletSheet';
        const dialogData = {
            width: '320px',
            minHeight: '140px',
            data: { activityName: ACTIVITY },
            disableClose: true,
        };
        if (this.settingsConfirmationService.showConfirmationDialog(ACTIVITY)) {
            this.dialog
                .open(ActivityConfirmationDialogComponent, dialogData)
                .afterClosed()
                .pipe(filter((data) => data))
                .subscribe({
                    next: () => {
                        this.snackBarService.openSnackBar(
                            this.translate.instant(`DC.OPERATIONS_PAGE.PALLET_SHEET.SNACKBAR.START_PROCESS`),
                            SnackbarClass.DEFAULT
                        );
                        this.printPalletSheet(operation);
                    },
                });
        } else {
            this.snackBarService.openSnackBar(
                this.translate.instant(`DC.OPERATIONS_PAGE.PALLET_SHEET.SNACKBAR.START_PROCESS`),
                SnackbarClass.DEFAULT
            );
            this.printPalletSheet(operation);
        }
    }

    private printPalletSheet(operation: Operation): void {
        this.operationService.printPalletSheet(operation.primaryKey, this.machineId).subscribe({
            next: (resp) => {
                if (resp) {
                    this.snackBarService.openSnackBar(
                        this.translate.instant(`DC.OPERATIONS_PAGE.PALLET_SHEET.SNACKBAR.SUCCESS`),
                        SnackbarClass.SUCCESS
                    );
                } else {
                    this.snackBarService.openSnackBar(
                        this.translate.instant(`DC.OPERATIONS_PAGE.PALLET_SHEET.SNACKBAR.FAILURE`),
                        SnackbarClass.WARNING
                    );
                }
            },
            error: () => {
                this.snackBarService.openSnackBar(
                    this.translate.instant(`DC.OPERATION_PAGE.SNACKBAR.ERROR`),
                    SnackbarClass.ERROR
                );
            },
        });
    }

    public onDetailsClick(): void {
        const primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey];
        const machineId = this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId];
        localStorage.setItem(`Details_${machineId}_${primaryKey}`, JSON.stringify(this.detailsPageInformation));
    }

    private setTimeForEchart(metadata: RequestMetadata<Operation>): void {
        function getCurrentTime(actual: number, start: number): number {
            const nowTime = Date.now();
            return actual + (nowTime - (start || nowTime));
        }

        if (metadata.data === null || metadata.data === undefined) {
            return;
        }
        this.isOperationRunning = metadata.data.runningActivities?.length > 0;

        switch (metadata.data.opState) {
            case ActivityState.IN_PROGRESS: {
                this.plannedTime = metadata.data.plannedGoodProduction || 0;
                this.currentTime = getCurrentTime(metadata.data.actualGoodProduction, metadata.data.productionStart);
                break;
            }
            case ActivityState.SETUP: {
                this.plannedTime = metadata.data.plannedMakeReady || 0;
                this.currentTime = getCurrentTime(metadata.data.actualMakeReady, metadata.data.setupStart);
                break;
            }
            case ActivityState.STOPPED: {
                this.plannedTime = 0;
                this.currentTime = getCurrentTime(metadata.data.actualStopped, metadata.data.stoppedStart);
                break;
            }
            case ActivityState.CLEANUP: {
                this.plannedTime = 0;
                this.currentTime = getCurrentTime(metadata.data.actualCleanup, metadata.data.cleanupStart);
                break;
            }
            default: {
                this.plannedTime = metadata.data.plannedTotal || 0;
                this.currentTime = metadata.data.actualTotal || 0;
                break;
            }
        }
    }
}

@NgModule({
    declarations: [OperationComponent],
    exports: [OperationComponent],
    imports: [
        EchartTimeIndicatorModule,
        OperationAmountInfoModule,
        OperationToolbarButtonsModule,
        ActivityButtonsComponent,
        MatToolbarModule,
        HdmuiComponentsModule,
        CommonModule,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        LoadingIndicatorComponentModule,
        ErrorMessageComponentModule,
        TranslateModule,
        MetadataDirectivesModule,
        MatTooltipModule,
        HdmuiEmptyStatesModule,
        HdmuiIconsModule,
        HdmuiInfoBoxModule,
        LegalLineComponent,
        HelpButtonComponent,
        HdmuiAvatarComponent,
    ],
})
export class OperationComponentModule {}
