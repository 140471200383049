import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    concatMap,
    filter,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    ReplaySubject,
    share,
    startWith,
    Subject,
    switchMap,
    tap,
    timer,
    zip,
} from 'rxjs';

import {
    Activity,
    CreateOperation,
    Machine,
    NewOperation,
    Operation,
    OperationAction,
    OperationActionRequest,
    ROUTE_PARAMS,
    StartOperationActivity,
} from '@data-terminal/shared-models';
import {
    ApiRequestType,
    getParams,
    mapToRequestMetadataWithRetry,
    MS_IN_SECOND,
    RequestMetadata,
    RETRY_TIME_DELAY_MS,
} from '@data-terminal/utils';
import {
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
    OperationNavigationService,
} from '@data-terminal/data-access';

import { OperationService } from '../../services/operation/operation.service';
import { OngoingOperationDialogService } from '../../services/operation/ongoing-operation-dialog.service';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { GeneralActivitiesService } from '../../services/general-activities/general-activities.service';
import { ApiRequestResponseService } from '../../../../../data-access/src/lib/api-request-response/api-request-response.service';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IOTResponseError } from 'projects/shared-models/src/lib/datatransfer';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';
import { GenericUpdatesService } from '../../services/generic-updates/generic-updates.service';
import { NewOperationDialogService } from '../../services/new-operation-dialog/new-operation-dialog.service';

export interface OrderBagBody {
    machineId: string;
    jobId: string;
    page: number;
}

const TWENTY_SECONDS = 20;

@UntilDestroy()
@Injectable()
export class OperationPresenter {
    private readonly machineId: string = '';
    private readonly OPERATION_LIST_REQUEST_INTERVAL = TWENTY_SECONDS * MS_IN_SECOND;
    private readonly startActivity$ = new Subject<StartOperationActivity>();
    private readonly operationAction$ = new Subject<OperationActionRequest>();
    private readonly createOperation$ = new Subject<CreateOperation>();
    private readonly selectOperation$ = new ReplaySubject<string>(1);
    private readonly updateSelectOperation$ = new Subject<string>();
    private readonly counterboxUpdate$ = new Subject<string>();
    private runningOperation!: Operation | undefined;
    private allMachinesData: Machine[] = [];

    private counterboxTimestamp = '';

    public operationList$: Observable<RequestMetadata<Operation[]>>;
    public selectedOperation$: Observable<RequestMetadata<Operation>>;

    private readonly handleRunningOperations = (
        stream$: Observable<StartOperationActivity>
    ): Observable<StartOperationActivity> =>
        stream$.pipe(
            switchMap((startActivity: StartOperationActivity) => {
                if (
                    this.runningOperation &&
                    this.allMachinesData.find((machine) => machine.machineId === this.machineId)?.runningOperation
                        ?.primaryKey === this.runningOperation.primaryKey
                ) {
                    if (this.runningOperation.primaryKey !== startActivity.operation.primaryKey) {
                        return this.ongoingOperationDialogService.openOngoingOperationDialog(
                            startActivity,
                            this.runningOperation,
                            this.allMachinesData.find((machine) => machine.machineId === this.machineId)
                                ?.machineClass === 'ID_ManualPrePress',
                            this.counterboxTimestamp
                        );
                    }
                    return of(startActivity);
                } else {
                    return of(startActivity);
                }
            })
        );

    private readonly startActivity = this.startActivity$.pipe(
        this.handleRunningOperations,
        mergeMap((startActivity: StartOperationActivity) =>
            this.operationService.startActivity(startActivity).pipe(
                filter((d) => !d.isLoading),
                tap({
                    complete: () => {
                        this.userMachinesService.triggerUpdateMachines();
                        this.generalActivitiesService.triggerUpdateOtherActivities();
                    },
                })
            )
        ),
        share()
    );

    private readonly operationAction = this.operationAction$.pipe(
        mergeMap((operationRequestAction: OperationActionRequest) =>
            this.operationService.updateOperationAction(operationRequestAction).pipe(
                filter((d) => !d.isLoading),
                tap({
                    complete: () => {
                        this.userMachinesService.triggerUpdateMachines();
                        this.generalActivitiesService.triggerUpdateOtherActivities();
                    },
                })
            )
        ),
        share()
    );

    private readonly createOperation = this.createOperation$.pipe(
        mergeMap((createOperation) => {
            let timeout = true;
            const newOperation = createOperation.newOperation;
            return this.operationService.createNewOperation({ ...createOperation, machineId: this.machineId }).pipe(
                tap({
                    next: (data) => {
                        if (data.data !== null && data.data !== undefined && 'errorcode' in data.data) {
                            timeout = false;
                            const errorData = data.data as object as IOTResponseError;
                            if (errorData.errorcode !== undefined) {
                                this.dialog
                                    .open(ErrorDialogComponent, {
                                        disableClose: true,
                                        data: {
                                            errorCode: errorData.errorcode,
                                            errorMessage: JSON.stringify({
                                                payload: errorData.payload,
                                                request: errorData.request,
                                                deviceId: errorData.deviceId,
                                            }),
                                        },
                                    })
                                    .afterClosed()
                                    .subscribe({
                                        next: (error: IOTResponseError) => {
                                            if (error.payload !== undefined) {
                                                const prevDialogData = (error.payload as CreateOperation).newOperation;
                                                this.newOperationDialogService
                                                    .openDialogAndFilter(prevDialogData)
                                                    .subscribe({
                                                        next: (report: NewOperation) => {
                                                            this.triggerCreateOperation(report);
                                                        },
                                                    });
                                            }
                                        },
                                    });
                            }
                        }
                    },
                    complete: () => {
                        if (timeout) {
                            this.newOperationDialogService.openDialogAndFilter(newOperation).subscribe({
                                next: (report: NewOperation) => {
                                    this.triggerCreateOperation(report);
                                },
                            });
                        }
                    },
                })
            );
        })
    );

    private readonly selectOperation = this.selectOperation$.pipe(
        switchMap((primaryKey) => this.operationService.getOperationRequestMetadata(primaryKey)),
        startWith({ isLoading: true })
    );

    private readonly updateSelectOperation = this.genericUpdateService.selectedOperationUpdate$().pipe(
        switchMap((primaryKey) => this.operationService.getOperationRequestMetadata(primaryKey)),
        filter((d) => d.isLoading === false)
    );

    private readonly counterboxUpdate = this.counterboxUpdate$.pipe(
        switchMap((primaryKey) => this.operationService.getOperationRequestMetadata(primaryKey)),
        filter((d) => d.isLoading === false)
    );

    constructor(
        private readonly operationNavigation: OperationNavigationService,
        private readonly operationService: OperationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly ongoingOperationDialogService: OngoingOperationDialogService,
        private readonly userMachinesService: UserMachinesService,
        private readonly generalActivitiesService: GeneralActivitiesService,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        private readonly dialog: MatDialog,
        private readonly genericUpdateService: GenericUpdatesService,
        private readonly newOperationDialogService: NewOperationDialogService
    ) {
        this.globalState
            .select(GlobalStateSelectors.USER_MACHINES)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data.data !== null && data.data !== undefined) {
                    this.allMachinesData = data.data.allMachines;
                }
            });
        this.machineId = getParams(this.activatedRoute)[ROUTE_PARAMS.machineId];
        this.selectedOperation$ = merge(
            this.startActivity,
            this.operationAction,
            this.selectOperation,
            this.updateSelectOperation,
            this.counterboxUpdate
        );
        this.operationList$ = this.loadOperationList().pipe(
            tap((data) => this.setNavigation(data)),
            tap((data) => {
                const primaryKey = this.allMachinesData.find((machine) => machine.machineId === this.machineId)
                    ?.runningOperation?.primaryKey;

                this.runningOperation = data.data?.find((op) => op.primaryKey === primaryKey);
            }),
            map((data) => {
                data.data?.sort((a, b) => {
                    const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                    const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                    return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                });
                return data;
            })
        );

        this.updateSelectOperation$ = genericUpdateService.selectedOperationUpdate$();
    }

    public triggerStartOperationActivity(
        activity: Activity,
        operation: Operation,
        machineId: string,
        counterBoxTimestamp = ''
    ): void {
        this.counterboxTimestamp = counterBoxTimestamp;
        this.startActivity$.next({ activity, operation, machineId });
    }

    public triggerSendOperationAction(operationAction: OperationAction, machineId: string, primaryKey: string): void {
        this.operationAction$.next({ operationAction, machineId, primaryKey });
    }

    public triggerCreateOperation(operation: NewOperation): void {
        this.createOperation$.next({ newOperation: operation });
    }

    public triggerSelectOperation(primaryKey: string): void {
        this.selectOperation$.next(primaryKey);
    }

    public triggerCounterboxUpdate(primaryKey: string): void {
        this.counterboxUpdate$.next(primaryKey);
    }

    public triggerOrderBag(primaryKey: string): Observable<string | object> {
        const body: OrderBagBody = {
            machineId: this.machineId,
            jobId: primaryKey,
            page: 1,
        };
        return this.apiRequestResponseService.sendApiRequest<OrderBagBody, string>(ApiRequestType.ORDERBAG, body).pipe(
            filter((data) => !data.isLoading),
            filter((data) => data.data !== undefined),
            map((data) => data.data || 'ERROR')
        );
    }

    private loadOperationList(): Observable<RequestMetadata<Operation[]>> {
        const getOperationList$ = this.operationService.getOperationList(this.machineId).pipe(
            mapToRequestMetadataWithRetry({
                baseDelayMs: RETRY_TIME_DELAY_MS,
            }),
            filter((metadata) => !!metadata?.data)
        );

        const interval$ = timer(0, this.OPERATION_LIST_REQUEST_INTERVAL);

        const triggerLoadListAgain$ = merge(this.startActivity, this.operationAction).pipe(
            filter((data) => !data.isLoading)
        );

        const allMachinesData = this.allMachinesData.find((machine) => machine.machineId === this.machineId);
        if (allMachinesData !== undefined) {
            const runningOperationPrimaryKey = allMachinesData.runningOperation?.primaryKey;
            if (
                runningOperationPrimaryKey !== undefined &&
                runningOperationPrimaryKey !== null &&
                !runningOperationPrimaryKey.startsWith('_') &&
                runningOperationPrimaryKey.length > 0
            ) {
                const otherMachineOperation$ = this.operationService
                    .getOperationRequestMetadata(runningOperationPrimaryKey)
                    .pipe(filter((data) => !data.isLoading));

                const fullOperationList$ = zip(getOperationList$, otherMachineOperation$).pipe(
                    map((x) => {
                        const fullOperationList: Operation[] = [];
                        const getOperationListData = x[0].data;
                        if (getOperationListData === null || getOperationListData === undefined) {
                            throw Error('getOperationList Data is undefined');
                        }
                        const otherMachineOperationData = x[1].data;
                        if (otherMachineOperationData === null || otherMachineOperationData === undefined) {
                            throw Error('otherMachineOperationData Data is undefined');
                        }
                        for (const operation of [...getOperationListData.concat(otherMachineOperationData)]) {
                            if (!fullOperationList.find((op) => op.primaryKey === operation.primaryKey)) {
                                fullOperationList.push(operation);
                            }
                        }
                        fullOperationList.sort((a, b) => {
                            const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                            const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                            return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                        });
                        return fullOperationList;
                    }),
                    mapToRequestMetadataWithRetry({
                        baseDelayMs: RETRY_TIME_DELAY_MS,
                    })
                );
                return merge(triggerLoadListAgain$, interval$, this.createOperation).pipe(
                    concatMap(() => fullOperationList$),
                    share(),
                    startWith({ isLoading: true })
                );
            }
        }
        return merge(triggerLoadListAgain$, interval$, this.createOperation).pipe(
            concatMap(() => getOperationList$),
            share(),
            startWith({ isLoading: true })
        );
    }

    private setNavigation(metadata: RequestMetadata<Operation[]>): void {
        if (metadata.data) {
            const machineRunningOperation = this.allMachinesData.find(
                (machine) => machine.machineId === this.machineId
            )?.runningOperation;
            const runningOperations =
                metadata.data.filter(
                    (op) =>
                        op.runningActivities.length > 0 &&
                        this.allMachinesData.find((machine) => machine.machineId === this.machineId)?.runningOperation
                            ?.primaryKey === op.primaryKey
                ).length +
                (machineRunningOperation?.primaryKey !== undefined &&
                machineRunningOperation?.primaryKey !== '' &&
                metadata.data.filter((op) => op.primaryKey === machineRunningOperation?.primaryKey).length === 0
                    ? 1
                    : 0);
            this.operationNavigation.updateOperationTab({
                badge: runningOperations,
                isDisabled: false,
            });
        }
    }
}
