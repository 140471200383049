import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentType } from '@angular/cdk/overlay';
import { BehaviorSubject, combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RxState } from '@rx-angular/state';
import { TranslateService } from '@ngx-translate/core';

import {
    Machine,
    RequestType,
    SignOffDialogConfig,
    SignOffMachine,
    SnackbarClass,
    TimeModeEntry,
    User,
    UserMachines,
} from '@data-terminal/shared-models';
import { getParams, HplusRedirectionService, SnackBarService } from '@data-terminal/utils';
import {
    createInitialDialogProcessConfig,
    prepareUserMachinesToSignOff,
    SignOffService,
    updateDialogProcessConfig,
    updatesOperation,
} from '@data-terminal/feature-dialogs';
import {
    DataTerminalSessionService,
    GlobalState,
    GlobalStateSelectors,
    NotificationSendingService,
} from '@data-terminal/data-access';

import { EndShiftDialogComponent } from '../../dialogs/end-shift-dialog/end-shift-dialog.component';
import { SignOffDialogComponent } from '../../dialogs/sign-off-dialog/sign-off-dialog.component';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { ErrorDialogComponent } from '../../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';
import { PureTimeApiService } from '../../../../../feature-workstation-details/src/lib/services/pure-time-api/pure-time-api.service';

const RETRY_COUNT = 15;

export abstract class AbstractSignOffProcessService {
    protected user!: User;

    protected signOffMachines!: SignOffMachine[];

    protected readonly dialogConfig$ = new BehaviorSubject<SignOffDialogConfig>({
        employees: false,
        summary: false,
        operations: false,
        workstations: false,
    });
    protected readonly signOffMachines$ = new BehaviorSubject<SignOffMachine[]>([]);

    protected dialogConfirmed = false;

    constructor(
        protected readonly dialog: MatDialog,
        protected readonly signOffService: SignOffService,
        protected readonly router: Router,
        protected readonly activatedRoute: ActivatedRoute,
        protected readonly userMachinesService: UserMachinesService,
        protected readonly globalState: RxState<GlobalState>,
        protected readonly notificationservice: NotificationSendingService,
        protected readonly translate: TranslateService,
        protected readonly snackBarService: SnackBarService,
        protected readonly sessionService: DataTerminalSessionService,
        protected readonly hplusRedirectionService: HplusRedirectionService,
        protected readonly pureTimeApiService: PureTimeApiService
    ) {}

    protected abstract prepareStream(
        unsubmittedTimeEntries: TimeModeEntry[],
        user?: User
    ): Observable<SignOffMachine[]>;

    public startProcess(user: User, i18nPrefix: string, endApplication = false): Observable<UserMachines> {
        let i = 0;
        let error = false;
        let errorMsg = '';
        let isCancled = true;
        return combineLatest([
            this.userMachinesService.getUserMachines(),
            this.pureTimeApiService.getUnsubmittedTimeEntries(),
        ]).pipe(
            take(1),
            tap(() => {
                this.user = user;
                this.signOffMachines = prepareUserMachinesToSignOff(user.userMachines, user);
                this.signOffMachines$.next(this.signOffMachines);
            }),
            switchMap(([, unsubmittedTimeEntries]) => this.prepareStream(unsubmittedTimeEntries, user)),
            switchMap((data: SignOffMachine[]) => {
                return this.signOffService.signOffMachines(data, this.user);
            }),
            tap((data: UserMachines) => {
                this.checkCurrentWorkstation(data);
            }),
            tap(() => {
                const leaveMachines = this.signOffMachines$.value
                    .filter((machine) => machine.assistantsSignOff === 'LEAVE')
                    .map((machine) => machine.machine);
                if (this.dialogConfirmed) {
                    for (const machine of leaveMachines) {
                        const signOffMachine = machine.machineId;
                        const signOffUserList = machine.signedOnUsers
                            .filter((signedOnUser) => signedOnUser.captain === false)
                            .map((signedOnUser) => signedOnUser.userId);
                        this.notificationservice
                            .sendUserSignOffLeaveNotification(
                                `${this.sessionService.getCurrentDataTerminalUser().firstName} ${
                                    this.sessionService.getCurrentDataTerminalUser().lastName
                                }`,
                                signOffMachine,
                                signOffUserList
                            )
                            .subscribe();
                    }
                }
            }),
            tap({
                next: (response) => {
                    isCancled = false;
                    i += 1;
                    if (response !== undefined && response !== null) {
                        error = response.serviceError.error;
                        errorMsg = response.serviceError.errorMsg;
                    }
                },
                error: () => {
                    this.snackBarService.openSnackBar(
                        this.translate.instant(`${i18nPrefix}.SNACKBAR.ERROR`),
                        SnackbarClass.ERROR
                    );
                },
                complete: () => {
                    console.log(error);
                    if (error) {
                        if (errorMsg !== '') {
                            this.dialog.open(ErrorDialogComponent, {
                                disableClose: true,
                                data: {
                                    errorCode: 'ERR_SIGNOFF',
                                    errorMessage: JSON.stringify({
                                        payload: JSON.parse(errorMsg),
                                        request: RequestType.SIGNOFF_SHIFTEND,
                                        deviceId: '',
                                    }),
                                },
                            });
                        }
                    }
                    if (i <= RETRY_COUNT && !error) {
                        if (i > 0) {
                            this.snackBarService.openSnackBar(
                                this.translate.instant(`${i18nPrefix}.SNACKBAR.SUCCESS`),
                                SnackbarClass.SUCCESS
                            );
                        }
                        if (endApplication && !isCancled) {
                            this.hplusRedirectionService.redirect();
                        }
                    } else {
                        if (!error) {
                            this.dialog.open(ErrorDialogComponent, {
                                disableClose: true,
                                data: {
                                    errorCode: 'ERR_SIGNOFF',
                                    errorMessage: JSON.stringify({
                                        payload: { payload: [] },
                                        request: RequestType.SIGNOFF_SHIFTEND,
                                        deviceId: '',
                                    }),
                                },
                            });
                        }
                    }
                },
            })
            // tap(() => this.transitionService.navigateToFarewellPage()) // TODO: integrate farewell page to machine-sign-off process)
        );
    }

    public getMachineToSignOff(): Observable<SignOffMachine[]> {
        return this.signOffMachines$.asObservable();
    }

    public getConfig(): Observable<SignOffDialogConfig> {
        return this.dialogConfig$.asObservable();
    }

    public onOperationStateChange(signOffMachines: SignOffMachine[]): void {
        this.signOffMachines$.next(signOffMachines);
    }

    public onEmployeesStateChange(signOffMachines: SignOffMachine[]): void {
        const updatedSignOffMachines = signOffMachines.map((el) => updatesOperation(el));
        this.signOffMachines$.next(updatedSignOffMachines);
        this.dialogConfig$.next(updateDialogProcessConfig(updatedSignOffMachines, this.user));
    }

    public onWorkstationStateChange(machines: Machine[]): void {
        const signOffMachines = prepareUserMachinesToSignOff(machines, this.user);
        this.signOffMachines$.next(signOffMachines);
        this.dialogConfig$.next(updateDialogProcessConfig(signOffMachines, this.user));
    }

    public openDialog(
        dialogComponent: ComponentType<EndShiftDialogComponent | SignOffDialogComponent>,
        unsubmittedTimeEntries: TimeModeEntry[],
        isEndShift = false
    ): Observable<SignOffMachine[]> {
        const dialogConfig = createInitialDialogProcessConfig(
            this.user.userMachines,
            this.user,
            unsubmittedTimeEntries,
            isEndShift
        );
        this.dialogConfig$.next(dialogConfig);

        return this.dialog
            .open(dialogComponent, {
                height: '80%',
                width: '680px',
                disableClose: true,
                data: {
                    data: this.user.userMachines,
                    userId: this.user.userId,
                    otherActivities$: this.globalState.select(GlobalStateSelectors.OTHER_ACTIVITIES),
                    unsubmittedTimeEntries: unsubmittedTimeEntries,
                },
            })
            .afterClosed()
            .pipe(
                filter((data) => !!data),
                tap((data) => {
                    this.dialogConfirmed = data;
                }),
                map(() => this.signOffMachines$.value)
            );
    }

    public checkCurrentWorkstation(data: UserMachines): void {
        const { machineId, orgId } = getParams(this.activatedRoute);
        if (machineId) {
            if (this.shouldLogoutFormCurrentWorkstation(data, machineId)) {
                this.redirectToDashboard(orgId);
            }
        } else {
            this.signOffService.dispatchMachines(data);
        }
    }

    private shouldLogoutFormCurrentWorkstation(data: UserMachines, currentMachine: string): boolean {
        return !data.assignedMachines?.find((machine) => machine.machineId === currentMachine);
    }

    private redirectToDashboard(orgId: string): void {
        const url = `${orgId}/dashboard`;
        this.router.navigateByUrl(url);
    }
}
