import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgModule,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DatePipe, DOCUMENT } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog } from '@angular/material/dialog';
import { Observable, filter, interval, take, tap } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';

import { CustomDividerModule, SearchInputComponent, SearchInputModule } from '@data-terminal/ui-presentational';
import {
    Machine,
    MachineClass,
    NewOperation,
    Operation,
    OperationCardAttribute,
    OperationFilter,
    ROUTE_PARAMS,
} from '@data-terminal/shared-models';
import { getParams } from '@data-terminal/utils';
import { OperationCardModule, BarcodeSearchDialogService } from '@data-terminal/ui-smart';
import { OperationCardAttributeOrderService } from '@data-terminal/settings';

import { NewOperationDialogComponent } from './new-operation-dialog/new-operation-dialog.component';
import { filterOperations, hasAnyFilterApplied, searchInputFilter } from './operation-list.filter';
import { HdmuiEmptyStatesModule, HdmuiIconsModule } from '@heidelberg/hdmui-angular';
import { OperationService } from '../../../services/operation/operation.service';
import { JobService } from '../../../services/job/job.service';
import { GLOBAL_RX_STATE, GlobalState, GlobalStateSelectors } from '@data-terminal/data-access';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
const MIN_SEARCHPHRASE_LENGTH = 3;
const REFRESH_INTERVAL_5000 = 5000;

enum OtherOperationsType {
    FINISHED_OPERATION = 'finishedOperation',
    OTHER_MACHINE_OPERATION = 'otherMachineOperation',
}

@UntilDestroy()
@Component({
    selector: 'data-terminal-operations-list',
    templateUrl: './operations-list.component.html',
    styleUrls: ['./operations-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationsListComponent implements OnChanges, OnInit, AfterViewInit {
    @ViewChild(SearchInputComponent) searchInputComponent!: SearchInputComponent;

    @Input() public operations: Operation[] = [];
    @Input() public selectedOperation!: Operation;
    @Input() public filter?: OperationFilter;
    @Output() public filterClick = new EventEmitter<void>();
    @Output() public operationClick = new EventEmitter<Operation>();
    @Output() public createOperation = new EventEmitter<NewOperation>();
    public _operations: Operation[];

    public showFilterBadge = false;

    public notRunningOperations: Operation[] = [];
    public runningOperations: Operation[] = [];
    public finishedOperations: Operation[] = [];
    public otherDeviceOperations: Operation[] = [];
    public moreOperationsNames: string[] = [];
    public enableMoreOperations = false;
    public attrOrder$: Observable<string[]>;
    public attrOrder: string[] = [];
    public readonly machineClass: MachineClass;

    public searchPhrase = '';
    private readonly currentMachine: string;

    public allMachinesData: Machine[] = [];
    private runningActivityPrimaryKey = '';

    private isInitiated = false;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly datePipe: DatePipe,
        private readonly translate: TranslateService,
        private readonly operationCardAttributeOrderService: OperationCardAttributeOrderService,
        private readonly barcodeSearchDialogService: BarcodeSearchDialogService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly operationService: OperationService,
        private readonly jobService: JobService,
        private readonly activatedRoute: ActivatedRoute,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>
    ) {
        this._operations = this.getOperationsCopy();
        this.machineClass = this.route.snapshot.data.machineClass;
        this.currentMachine = getParams(route)[ROUTE_PARAMS.machineId];
        this.attrOrder$ = this.operationCardAttributeOrderService
            .getOperationCardOrderNames$(this.machineClass.toUpperCase() as MachineClass)
            .pipe(tap((order) => (this.attrOrder = order)));
        this.loadAdditionalOperationsFromStorage();
        this.globalState
            .select(GlobalStateSelectors.USER_MACHINES)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data.data !== null && data.data !== undefined) {
                    this.allMachinesData = data.data.allMachines;
                }
                this.refreshOperation();
            });
        this.refreshOperation();
        interval(REFRESH_INTERVAL_5000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateOperationList();
                this.refreshOperation();
                cdRef.detectChanges();
            });
    }

    public ngOnInit(): void {
        this._operations = this.operations;
        this.refreshOperation();
        this.focusSelectedOperation();
    }

    public ngAfterViewInit(): void {
        const primaryKey = getParams(this.route)[ROUTE_PARAMS.primaryKey];
        setTimeout(() => this.document?.getElementById(primaryKey)?.scrollIntoView({ behavior: 'smooth' }), 1000);
        this.updateOperationList();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.refreshOperation();
        this.refreshSelectedOperation();

        if (changes.filter) {
            this.showFilterBadge = hasAnyFilterApplied(this.filter);
        }
    }

    public updateOperationList(): void {
        this._operations = this.operations;
        this.runningActivityPrimaryKey =
            this.allMachinesData.find((machine) => machine.machineId === this.currentMachine)?.runningOperation
                ?.primaryKey || '';
        if (this.runningActivityPrimaryKey.length > 0) {
            if (!this._operations.find((op) => op.primaryKey === this.runningActivityPrimaryKey)) {
                this.operationService
                    .getOperationRequestMetadata(this.runningActivityPrimaryKey)
                    .pipe(untilDestroyed(this))
                    .subscribe((metadata) => {
                        if (metadata.data !== null && metadata.data !== undefined) {
                            const arrBuffer: Operation[] = [];

                            for (const operation of [...this.operations, metadata.data]) {
                                if (!arrBuffer.find((ele) => ele.primaryKey === operation.primaryKey)) {
                                    arrBuffer.push(operation);
                                }
                            }
                            this._operations = arrBuffer;
                            this.refreshOperation();
                        }
                    });
            }
        } else {
            const filteredOperations = this.operations.filter((op) => op.plannedMachine === this.currentMachine);
            if (filteredOperations.length !== this._operations.length) {
                this._operations = filteredOperations;
                this.refreshOperation();
            }
        }
    }

    public onSearchInputChange(searchPhrase: string): void {
        this.searchPhrase = searchPhrase;
        this.refreshOperation();
        if (this.searchPhrase.length > MIN_SEARCHPHRASE_LENGTH) {
            this.jobService
                .getJobList(this.searchPhrase)
                .pipe(take(1))
                .subscribe((data) => {
                    this.moreOperationsNames = data.map((d) => d.jobId);
                    this.enableMoreOperations = !!this.moreOperationsNames.find((ele) => ele === this.searchPhrase);
                });
            if (this.moreOperationsNames.find((ele) => ele === this.searchPhrase)) {
                this.enableMoreOperations = true;
            } else {
                this.enableMoreOperations = false;
                sessionStorage.removeItem(this.getsessionStorageOtherOperationsByMachineKey(this.currentMachine));
            }
        } else {
            this.moreOperationsNames = [];
            this.enableMoreOperations = false;
            sessionStorage.removeItem(this.getsessionStorageOtherOperationsByMachineKey(this.currentMachine));
        }
        if (!this.enableMoreOperations) {
            this.finishedOperations = [];
            this.otherDeviceOperations = [];
        }
        this.refreshSelectedOperation();
        this.cdRef.detectChanges();
    }

    public onBarcodeClick(): void {
        this.barcodeSearchDialogService
            .openBarcodeDialogForSearch(this.searchInputComponent)
            .afterClosed()
            .pipe(take(1))
            .subscribe((extractedValue) => {
                this.onSearchInputChange(extractedValue);
            });
    }

    public onFilterClick(): void {
        this.filterClick.emit();
    }

    public onCardSelect($event: Operation): void {
        this.selectedOperation = $event;
        this.operationClick.emit($event);
    }

    public identify(index: number, item: Operation): string {
        return item.primaryKey;
    }

    public onOperationAdd(): void {
        const data: NewOperation = {
            noAmountsRequired: this.machineClass === 'ID_ManualPrePress',
        };
        this.dialog
            .open(NewOperationDialogComponent, {
                width: '320px',
                minHeight: '140px',
                maxHeight: '750px',
                height: '100%',
                disableClose: true,
                data,
            })
            .afterClosed()
            .pipe(filter((dialogData) => dialogData))
            .subscribe({
                next: (report: NewOperation) => {
                    this.createOperation.emit(report);
                },
            });
    }

    public getOperationsCopy(): Operation[] {
        return [...this.operations];
    }

    public _getOperationsCopy(): void {
        this._operations = [...this.operations];
        this.refreshOperation();
    }

    private refreshOperation(): void {
        this._operations = this.operations;
        let operationsCopy = [...this._operations];
        operationsCopy = filterOperations(this.filter, operationsCopy);
        operationsCopy = searchInputFilter(
            this.searchPhrase,
            operationsCopy,
            this.datePipe,
            this.translate,
            this.attrOrder as OperationCardAttribute[]
        );
        this.sortOperations(operationsCopy);
    }

    private sortOperations(operations: Operation[]): void {
        this.notRunningOperations = [];
        this.runningOperations = [];
        operations.forEach((opr) => {
            if (
                opr.runningActivities === undefined ||
                opr.runningActivities.length === 0 ||
                (opr.runningActivities.length === 1 && opr.runningActivities[0] === '')
            ) {
                this.notRunningOperations.push(opr);
            } else {
                if (
                    this.allMachinesData.find((machine) => machine.machineId === this.currentMachine)?.runningOperation
                        ?.primaryKey === opr.primaryKey
                ) {
                    // is actual machine
                    if (!this.runningOperations.find((op) => op.primaryKey === opr.primaryKey)) {
                        this.runningOperations.push(opr);
                    }
                } else {
                    this.notRunningOperations.push(opr);
                }
            }
        });
    }

    private focusSelectedOperation(): void {
        const params = getParams(this.route);

        this.selectedOperation =
            this._operations.find((el) => el.primaryKey === params[ROUTE_PARAMS.primaryKey]) ||
            this.runningOperations[0] ||
            this.notRunningOperations[0];
    }

    public getMachineString(): string {
        return `${this.currentMachine}-${this.machineClass}`;
    }

    public onLoadFinishedOperations(): void {
        this.operationService
            .completedOperationlist(this.currentMachine, this.searchPhrase)
            .pipe(take(1))
            .subscribe((data) => {
                data.sort((a, b) => {
                    const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                    const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                    return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                });
                this.finishedOperations = data;
                this.setsessionStorageOtherOperations(OtherOperationsType.FINISHED_OPERATION, this.searchPhrase);
            });
    }

    public onLoadAllWorkstepsJobDeviceClass(): void {
        this.operationService
            .operationlistByClass(this.currentMachine, this.searchPhrase)
            .pipe(take(1))
            .subscribe((data) => {
                data.sort((a, b) => {
                    const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                    const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                    return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                });
                this.otherDeviceOperations = data;
                this.setsessionStorageOtherOperations(OtherOperationsType.OTHER_MACHINE_OPERATION, this.searchPhrase);
            });
    }

    private refreshSelectedOperation(): void {
        if (!this.isInitiated) {
            this.isInitiated = true;
            this.focusSelectedOperation();
        }
        if (
            !this.runningOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.notRunningOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.finishedOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.otherDeviceOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey)
        ) {
            this.selectedOperation = {} as Operation;
            this.operationClick.emit({} as Operation);
        }
    }

    public onCreateUnplannedWorkInstant(): void {
        this.createOperation.emit({ jobId: this.searchPhrase });
    }

    private getsessionStorageOtherOperationsByMachineKey(machineId: string): string {
        return `LOADED_OTHER_OPERATIONS_${machineId}`;
    }

    private setsessionStorageOtherOperations(type: OtherOperationsType, searchPhrase: string): void {
        sessionStorage.setItem(
            this.getsessionStorageOtherOperationsByMachineKey(this.currentMachine),
            JSON.stringify({ type: type, searchPhrase: searchPhrase })
        );
    }

    private loadAdditionalOperationsFromStorage(): void {
        const sessionStorageOtherOperations = sessionStorage.getItem(
            this.getsessionStorageOtherOperationsByMachineKey(this.currentMachine)
        );
        if (sessionStorageOtherOperations !== null && sessionStorageOtherOperations !== undefined) {
            const otherOperations = JSON.parse(sessionStorageOtherOperations);
            this.searchPhrase = otherOperations.searchPhrase;
            if (otherOperations.type === OtherOperationsType.FINISHED_OPERATION) {
                this.onLoadFinishedOperations();
            }
            if (otherOperations.type === OtherOperationsType.OTHER_MACHINE_OPERATION) {
                this.onLoadAllWorkstepsJobDeviceClass();
            }
            this.jobService
                .getJobList(this.searchPhrase)
                .pipe(take(1))
                .subscribe((data) => {
                    this.moreOperationsNames = data.map((d) => d.jobId);
                    this.enableMoreOperations = !!this.moreOperationsNames.find((ele) => ele === this.searchPhrase);
                });
        }
    }
}

@NgModule({
    declarations: [OperationsListComponent],
    exports: [OperationsListComponent],
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        OperationCardModule,
        CommonModule,
        TranslateModule,
        CustomDividerModule,
        MatDividerModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        SearchInputModule,
        MatBadgeModule,
        HdmuiEmptyStatesModule,
        HdmuiIconsModule,
    ],
})
export class OperationsListModule {}
